import { useState, useRef, useCallback, useEffect } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import AddVolumePartner from './modals/AddVolumePartner';
import { Toast } from 'primereact/toast';

import moment from "moment";
import { Link } from "react-router-dom";
import { simplify, startTime, endTime, debounce, balanceFormatter, currencyFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";
import DataExportCSV from "./exports/DataExportCSV";
import useVolumePartner from "hooks/useVolumePartner";
import EditVolumePartner from "./modals/EditVolumePartner";
import DeleteVolumePartner from "./modals/DeleteVolumePartner";
import { formatThousandSeparator } from "helpers/functions";
import '../../../assets/css/style.css'
import TransactionHistoryModal from "./modals/TransactionHistoryModal";
import SortHeader from "pages/SortHeader";

const VolumePartnersTable = ({ id, searchId, lazyState, setlazyState, setSortField }) => {
    const [search, setSearch] = useState(id || searchId || '')
    const lazyStateRef = useRef(lazyState); // Create a ref to keep track of the latest lazyState
    useEffect(() => {
        lazyStateRef.current = lazyState;
    }, [lazyState]);
    const { totalRecords, setLoading, loading, setRefresh, refresh, volumePartners } = useVolumePartner();
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => {
        return {
            UCO_Approved: true
        }
    }); //prevent placeholder to have red background
    const delay = 1000;
    const today = new Date(); // Create a date with the correct month (0-indexed)
    const monthName = today.toLocaleDateString("en-US", { month: "long" });

    const [sortStates, setSortStates] = useState({
        sort1: 'idle',
        sort2: 'idle',
    });

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const changeFilter = (value) => {
        const _filters = { ...lazyStateRef.current }; // Get the latest lazyState from the ref
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = useCallback(debounce(changeFilter, delay), []);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        setLoading(true);
        debouncedSearch(value);
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const badgeDirectPayment = (data) => {
        let badgeText = data.direct_payment_enabled ? "ENABLED" : "DISABLED";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.direct_payment_enabled === true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }
    const badgeBonus = (data) => {
        let badgeText = data.bonus_enabled ? "Yes" : "No";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.bonus_enabled === true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }

    const fixedPrice = (data) => {
        if (data.is_fixed_price) {

            return balanceFormatter(String(data?.fixed_price ?? 0), data?.user?.currency ?? 'IDR');
        } else {
            return (
                <>
                    {balanceFormatter(String(data?.fixed_price ?? 0), data?.user?.currency ?? 'IDR')}
                    <br/>
                    <span className="badge bg-primary text-t-primary">
                        App Price
                    </span>
                </>
            );

        }
    }
    const expectedVolume = (data) => {
        return formatThousandSeparator(String(data.expected_volume ?? 0));
    }
    const actualVolume = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" />
                {" / "}
                {formatThousandSeparator(String(data.expected_volume ?? 0))}
            </>
        );
    }
    const collectedVolume = (data) => {
        return <TransactionHistoryModal rowData={data} options={lazyState} type="custom" />
    }

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditVolumePartner rowData={data} />
                </div>
                {/* <div>
                    <DeleteVolumePartner rowData={data} />
                </div> */}
            </div>
        )
    }
    const AppUsername = (data) => {
        return <Link to={`/userlist/${data?.user?._id}`}> {data?.user?.username} </Link>
    }

    const partnerName = (data) => {
        return <Link to={`/partner-overview/${data?.partner_account}`}> {data?.partner_account} </Link>
    }

    const headerMonthlyVolume = () => {
        return <SortHeader title={<span>{monthName} Vol.<br/>(Actual / Exp.)</span>} sortStates={sortStates} setSortStates={setSortStates} setSortField={setSortField} field={'sort1'} />;
    }

    const headerTotalVolume = () => {
        return <SortHeader   title={<span>Total Collected<br/> Volume</span>} sortStates={sortStates} setSortStates={setSortStates} setSortField={setSortField} field={'sort2'} />;
    }

    

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'partner_account', header: 'Partner Name', body: partnerName, className: '' },
        { field: '', header: 'App Username', body: AppUsername, className: '' },
        { field: '', header: 'Price', body: fixedPrice, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } },
        { field: 'collected_volume', header: (<span>{monthName} Vol.<br/>(Actual / Exp.)</span>), body: actualVolume, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' }, sortable: true },
        { field: 'total_collected_volume', header: (<span>Total Collected<br/> Volume</span>), body: collectedVolume, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' }, sortable: true },
        { field: '', header: (<><div>Direct</div><div>Payment</div></>), body: badgeDirectPayment, className: '' },
        { field: '', header: (<><div>Bonus &</div><div>Promotions</div></>), body: badgeBonus, className: '' },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    return (
        <Row>
            <Col xl={12}>
                <Card >
                    <CardBody>
                        <Row className="align-items-center">
                            <Col md={6} xs={12} className="d-flex flex-row align-items-center gap-3">
                            </Col>
                            <Col md={5} xs={8} className="mb-2">
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Partner Name / Username" className='p-inputtext-sm user-filter' style={{ width: '240px' }} />
                                </span>
                            </Col>
                            <Col md={1} xs={4} className="mb-2">
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <DataExportCSV lazyState={lazyState} />
                                </div>
                            </Col>
                        </Row>


                        <DataTable
                            // lazy
                            removableSort
                            value={loading ? items : volumePartners}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            // onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={loading ? '' : '_id'}
                            defaultSortOrder={-1}
                        >
                            {/* <Column expander={true} style={{ width: '3rem' }} /> */}
                            {columns.map((col, i) => (
                                <Column
                                    key={col.field}
                                    sortable={col.sortable}
                                    field={col.field}
                                    header={col.header}
                                    headerClassName={col.headerClassName}
                                    style={col.style}
                                    body={loading ? <Skeleton></Skeleton> : col.body}
                                    bodyClassName={col.className}
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default VolumePartnersTable